'use client';

import { useEffect } from 'react';
import { Button } from '../src/components/ui/button';

export default function GlobalError({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);

  return (
    <html>
      <body>
        <div className="flex flex-col items-center justify-center min-h-screen space-y-4 text-center p-4">
          <h2 className="text-2xl font-bold">Something went wrong</h2>
          <p className="text-gray-600 max-w-md">
            A critical error occurred. Please try refreshing the page or contact
            support if the problem persists.
          </p>
          <Button onClick={() => reset()}>Try again</Button>
        </div>
      </body>
    </html>
  );
}
